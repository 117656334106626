<script setup lang="ts">
interface Props {
  type?: string;
  size?: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: "primary",
  size: "w-5 h-5",
});

const colour = computed(() => {
  switch (props.type) {
    case "success":
      return "bg-success";
    case "danger":
      return "bg-danger";
    case "primary":
      return "bg-primary";
    default:
      return "bg-primary";
  }
});
</script>

<template>
  <div :class="[size, colour]" class="shadow-lg border border-white rounded-full text-xs flex items-center justify-center text-white">
    <slot />
  </div>
</template>
